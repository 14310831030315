exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aviso-legal-index-js": () => import("./../../../src/pages/aviso-legal/index.js" /* webpackChunkName: "component---src-pages-aviso-legal-index-js" */),
  "component---src-pages-aviso-legal-privacidad-js": () => import("./../../../src/pages/aviso-legal/privacidad.js" /* webpackChunkName: "component---src-pages-aviso-legal-privacidad-js" */),
  "component---src-pages-aviso-legal-terminos-js": () => import("./../../../src/pages/aviso-legal/terminos.js" /* webpackChunkName: "component---src-pages-aviso-legal-terminos-js" */),
  "component---src-pages-blog-7-alimentos-no-puedes-traer-js": () => import("./../../../src/pages/blog/7-alimentos-no-puedes-traer.js" /* webpackChunkName: "component---src-pages-blog-7-alimentos-no-puedes-traer-js" */),
  "component---src-pages-blog-cigarrillos-y-tabaco-js": () => import("./../../../src/pages/blog/cigarrillos-y-tabaco.js" /* webpackChunkName: "component---src-pages-blog-cigarrillos-y-tabaco-js" */),
  "component---src-pages-blog-como-importar-plagas-puede-danar-economia-js": () => import("./../../../src/pages/blog/como-importar-plagas-puede-danar-economia.js" /* webpackChunkName: "component---src-pages-blog-como-importar-plagas-puede-danar-economia-js" */),
  "component---src-pages-blog-conoce-tus-derechos-como-viajero-js": () => import("./../../../src/pages/blog/conoce-tus-derechos-como-viajero.js" /* webpackChunkName: "component---src-pages-blog-conoce-tus-derechos-como-viajero-js" */),
  "component---src-pages-blog-equipaje-seguro-js": () => import("./../../../src/pages/blog/equipaje-seguro.js" /* webpackChunkName: "component---src-pages-blog-equipaje-seguro-js" */),
  "component---src-pages-blog-guia-para-declarar-aduanas-rd-js": () => import("./../../../src/pages/blog/guia-para-declarar-aduanas-rd.js" /* webpackChunkName: "component---src-pages-blog-guia-para-declarar-aduanas-rd-js" */),
  "component---src-pages-blog-guia-para-declarar-plantas-y-flores-js": () => import("./../../../src/pages/blog/guia-para-declarar-plantas-y-flores.js" /* webpackChunkName: "component---src-pages-blog-guia-para-declarar-plantas-y-flores-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-blog-viajar-animales-y-mascotas-js": () => import("./../../../src/pages/blog/viajar-animales-y-mascotas.js" /* webpackChunkName: "component---src-pages-blog-viajar-animales-y-mascotas-js" */),
  "component---src-pages-en-index-js": () => import("./../../../src/pages/en/index.js" /* webpackChunkName: "component---src-pages-en-index-js" */),
  "component---src-pages-en-legal-notice-index-js": () => import("./../../../src/pages/en/legal-notice/index.js" /* webpackChunkName: "component---src-pages-en-legal-notice-index-js" */),
  "component---src-pages-en-legal-notice-privacidad-js": () => import("./../../../src/pages/en/legal-notice/privacidad.js" /* webpackChunkName: "component---src-pages-en-legal-notice-privacidad-js" */),
  "component---src-pages-en-legal-notice-terminos-js": () => import("./../../../src/pages/en/legal-notice/terminos.js" /* webpackChunkName: "component---src-pages-en-legal-notice-terminos-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

